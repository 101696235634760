const scroll_to_top = document.querySelector(".scroll_to_top");

let lastScrollY = window.scrollY;
let scrollMaxY = document.documentElement.scrollHeight - document.documentElement.clientHeight;
let minY = 1000;    //always hide button when below this value (could be changed)

scroll_to_top.classList.add("scroll_to_top_hidden");

window.addEventListener("scroll", () => {

    if (window.scrollY === 0 || lastScrollY < window.scrollY || Math.round(window.scrollY) >= scrollMaxY || window.scrollY <= minY ){
        
        scroll_to_top.classList.add("scroll_to_top_hidden");
    }
    else if (lastScrollY > window.scrollY & (window.scrollY > minY) ){
        
        scroll_to_top.classList.remove("scroll_to_top_hidden");
        console.log(scrollMaxY);
        console.log(window.scrollY);
    
    }
    
    lastScrollY = window.scrollY;

});